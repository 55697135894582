<template>
  <Form
    :hide-reset="edit"
    :loading="loading"
    :title="title"
    @reset="onReset"
    @submit="onSubmit"
  >
    <v-row dense>
      <FormItem
        v-model="key"
        :readonly="edit"
        rules="required"
        type="text"
        name="Identificativo"
        :cols="5"
        :sm="4"
        :md="4"
        :lg="2"
        :xl="2"
      />
      <FormItem
        v-model="name"
        rules="required"
        type="text"
        name="Nome Progetto"
        :cols="5"
        :sm="6"
        :md="6"
        :lg="4"
        :xl="4"
      />
      <FormItem
        v-model="customer"
        rules="required"
        type="text"
        name="Cliente"
        :cols="4"
        :sm="4"
        :md="3"
        :lg="2"
        :xl="2"
      />
      <FormItem
        v-model="active"
        rules="required"
        type="select"
        :values="[
          { value: true, text: 'Attiva' },
          { value: false, text: 'Disattiva' },
        ]"
        name="Stato Iniziativa"
        :cols="3"
        :sm="3"
        :md="2"
        :lg="2"
        :xl="2"
      />
    </v-row>

    <v-tabs>
      <!-- videoassistenza -->
      <v-tab href="#tab-1"> Video Assistenza </v-tab>
      <v-tab-item value="tab-1">
        <v-card flat>
          <v-card-text>
            <v-row>
              <FormItem
                v-model="video"
                type="switch"
                name="Attiva funzionalità di Video Assistenza (QuickSupport)"
                :cols="12"
                :sm="12"
                :md="12"
                :lg="12"
              />
            </v-row>
            <h3 class="mt-2">Impostazioni Videochamata</h3>
            <v-row dense>
              <FormItem
                v-model="agent_video"
                type="switch"
                name="Video agente"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="agent_audio"
                type="switch"
                name="Audio agente"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="agent_video_autostart"
                type="switch"
                name="Autostart video"
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="agent_audio_autostart"
                type="switch"
                name="Autostart audio"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="agent_video_effects"
                type="switch"
                name="Effetti webcam"
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="agent_share_screen"
                type="switch"
                name="Condividi schermo"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
            </v-row>
            <v-row dense>
              <FormItem
                v-model="customer_video"
                type="switch"
                name="Video Cliente"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="customer_audio"
                type="switch"
                name="Audio Cliente"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="customer_video_autostart"
                type="switch"
                name="Autostart video"
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="customer_audio_autostart"
                type="switch"
                name="Autostart audio"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="customer_share_screen"
                type="switch"
                name="Condividi schermo"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
            </v-row>

            <h3 class="mt-4">Add-on sessione</h3>
            <v-row dense>
              <FormItem
                v-model="enable_recording"
                type="switch"
                name="Registra Sessioni"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="enable_chat"
                type="switch"
                name="Abilita Chat"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="enable_attachments"
                type="switch"
                name="Abilita Allegati"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="enable_gallery"
                type="switch"
                name="Galleria Immagini"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="enable_live_editor"
                type="switch"
                name="Editor Live"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
              <FormItem
                v-model="enable_closing_modal"
                type="switch"
                name="Form Esitazione"
                dense
                :cols="6"
                :sm="4"
                :md="3"
                :lg="2"
                :xl="2"
              />
            </v-row>

            <h3 class="mt-4">Privacy</h3>
            <v-row dense>
              <FormItem
                v-model="obfuscate_customer_address_on_close"
                type="switch"
                name="Cancella cliente a fine sessione"
                dense
                :cols="6"
                :sm="6"
                :md="5"
                :lg="4"
                :xl="4"
              />
              <FormItem
                v-model="disclaimer_show"
                type="switch"
                name="Mostra Schermata T&C"
                dense
                :cols="6"
                :sm="6"
                :md="5"
                :lg="4"
                :xl="4"
              />
            </v-row>
            <v-row dense>
              <FormItem
                v-model="disclaimer_url"
                type="text"
                name="Indirizzo Pagina T&C"
                dense
                clearable
                :cols="6"
                :sm="6"
                :md="5"
                :lg="4"
                :xl="4"
              />
            </v-row>
            <v-row dense>
              <FormEditor
                v-model="disclaimer_text"
                name="Termini e Condizioni"
                :cols="12"
                :sm="12"
                :md="12"
                :lg="10"
                :xl="9"
              />
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- /videoassistenza -->

      <!-- accesso remoto -->
      <v-tab href="#tab-2">Accesso Remoto</v-tab>
      <v-tab-item value="tab-2">
        <h3>Funzionalità attive</h3>
        <v-row dense>
          <FormItem
            v-model="remote_mobile"
            type="switch"
            name="Accesso Remoto piattaforma mobile"
            :lg="4"
          />
          <FormItem
            v-model="remote_desktop"
            type="switch"
            name="Accesso Remoto piattaforma Desktop"
            :lg="4"
          />
        </v-row>

        <h3 class="mt-2 mb-2">Link Applicativi</h3>
        <v-row dense>
          <FormItem
            v-model="app_android_url"
            type="text"
            name="App Android"
            dense
            clearable
            :cols="5"
            :sm="6"
            :md="6"
            :lg="4"
            :xl="4"
          />
        </v-row>
        <v-row dense>
          <FormItem
            v-model="app_ios_url"
            type="text"
            name="App iOS"
            dense
            clearable
            :cols="5"
            :sm="6"
            :md="6"
            :lg="4"
            :xl="4"
          />
        </v-row>
        <v-row dense>
          <FormItem
            v-model="app_osx_url"
            type="text"
            name="App OSX"
            dense
            clearable
            :cols="5"
            :sm="6"
            :md="6"
            :lg="4"
            :xl="4"
          />
        </v-row>
        <v-row dense>
          <FormItem
            v-model="app_win_url"
            type="text"
            name="App Windows"
            dense
            clearable
            :cols="5"
            :sm="6"
            :md="6"
            :lg="4"
            :xl="4"
          />
        </v-row>
      </v-tab-item>
      <!-- /accesso remoto -->

      <!-- privacy -->
      <v-tab href="#tab-3">Comunicazioni</v-tab>
      <v-tab-item value="tab-3">
        <h3 class="mb-2">Configurazioni Generali</h3>
        <v-row dense>
          <FormItem
            v-model="mail_sender_name"
            type="text"
            name="Mittente Mail"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="5"
            :lg="4"
            :xl="4"
          />

          <FormItem
            v-model="sms_sender"
            type="text"
            name="Mittente SMS"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="5"
            :lg="4"
            :xl="4"
          />

          <FormItem
            v-model="mail_heading"
            type="text"
            name="Intestazioni Mail"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="5"
            :lg="4"
            :xl="4"
          />
        </v-row>

        <h3 class="mb-2">Nuovi Utenti</h3>
        <v-row dense>
          <FormItem
            v-model="welcome_mail_subject"
            type="text"
            name="Oggetto Mail"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="3"
          />
          <FormItem
            v-model="welcome_mail_template_name"
            type="text"
            name="Template Mail"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="3"
          />
          <FormItem
            v-model="welcome_sms_template"
            type="text"
            name="Template SMS"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="3"
          />
        </v-row>

        <h3 class="mb-2">Nuovi Ticket</h3>
        <v-row dense align="center">
          <FormItem
            v-model="new_ticket_mail_subject"
            type="text"
            name="Oggetto Mail"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="3"
          />
          <FormItem
            v-model="new_ticket_mail_template_name"
            type="text"
            name="Template Mail"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="3"
          />
          <FormItem
            v-model="new_ticket_sms_template"
            type="text"
            name="Template SMS"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="3"
          />
        </v-row>

        <h3 class="mb-2">QuickSupport</h3>
        <v-row dense align="center">
          <FormItem
            v-model="qs_mail_subject"
            type="text"
            name="Oggetto Mail"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="3"
          />
          <FormItem
            v-model="qs_mail_template_name"
            type="text"
            name="Template Mail"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="3"
          />
          <FormItem
            v-model="qs_sms_template"
            type="text"
            name="Template SMS"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="3"
          />
          <FormItem
            v-model="qs_sms_short_url"
            type="switch"
            name="Abbrevia link SMS"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="3"
            :lg="2"
            :xl="2"
          />
        </v-row>
      </v-tab-item>
      <!-- /privacy -->

      <!-- avanzate -->
      <v-tab href="#tab-4">Avanzate</v-tab>
      <v-tab-item value="tab-4">
        <h3 class="my-2">Raggiungibilità</h3>
        <v-row dense>
          <FormItem
            v-model="fe_public_url"
            class="mt-1"
            type="text"
            name="Dominio Dedicato"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="5"
            :lg="4"
            :xl="4"
          />
        </v-row>

        <h3 class="my-2">Chiavi OpenTOK</h3>
        <v-row dense>
          <FormItem
            v-model="ot_api_key"
            type="text"
            name="OpenTOK API KEY"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="5"
            :lg="4"
            :xl="4"
          />
          <FormItem
            v-model="ot_api_secret"
            type="password"
            name="OpenTOK API Secret"
            dense
            clearable
            :cols="6"
            :sm="6"
            :md="5"
            :lg="4"
            :xl="4"
          />
        </v-row>

        <h3 class="mt-2 mb-2">Avanzate</h3>
        <v-row>
          <FormItem
            v-model="firebaseSettings"
            type="textarea"
            name="Service Account Firebase"
            :rows="5"
            :cols="12"
            :sm="12"
            :md="6"
            :lg="5"
            :xl="5"
            dense
            clearable
          />

          <FormItem
            v-model="prefix"
            type="text"
            name="Prefisso Gateway Push"
            :cols="3"
            :sm="3"
            :md="2"
            :lg="2"
            :xl="2"
          />
        </v-row>
      </v-tab-item>
      <!-- /avanzate -->
    </v-tabs>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormEditor from '@components/forms/FormEditor.vue'

const { mapFields } = createHelpers({
  getterType: 'adminProjects/getCurrent',
  mutationType: 'adminProjects/SET_CURRENT_FIELDS',
})

export default {
  name: 'ProjectsForm',
  components: {
    FormEditor,
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  computed: {
    ...mapFields([
      'key',
      'name',
      'prefix',
      'customer',
      'capabilities.video',
      'capabilities.remote_mobile',
      'capabilities.remote_desktop',
      'settings.agent_audio',
      'settings.agent_audio_autostart',
      'settings.agent_share_screen',
      'settings.agent_video',
      'settings.agent_video_autostart',
      'settings.agent_video_effects',
      'settings.customer_audio',
      'settings.customer_audio_autostart',
      'settings.customer_share_screen',
      'settings.customer_video',
      'settings.customer_video_autostart',
      'settings.app_android_url',
      'settings.app_ios_url',
      'settings.app_osx_url',
      'settings.app_win_url',
      'settings.mail_sender_name',
      'settings.mail_heading',
      'settings.sms_sender',

      'settings.qs_mail_subject',
      'settings.qs_mail_template_name',
      'settings.qs_sms_template',
      'settings.qs_sms_short_url',
      'settings.welcome_mail_subject',
      'settings.welcome_mail_template_name',
      'settings.welcome_sms_template',
      'settings.new_ticket_mail_subject',
      'settings.new_ticket_mail_template_name',
      'settings.new_ticket_sms_template',

      'settings.disclaimer_show',
      'settings.disclaimer_text',
      'settings.disclaimer_url',
      'settings.enable_closing_modal',
      'settings.enable_chat',
      'settings.enable_attachments',
      'settings.enable_gallery',
      'settings.enable_live_editor',
      'settings.enable_recording',
      'settings.fe_public_url',
      'settings.obfuscate_customer_address_on_close',
      'settings.ot_api_key',
      'settings.ot_api_secret',
      'active',
      'firebase_service_account',
    ]),
    ...mapState('adminProjects', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
    }),
    firebaseSettings: {
      get() {
        return JSON.stringify(this.firebase_service_account)
      },
      set(value) {
        this.firebase_service_account = JSON.parse(value)
      },
    },
  },
  async mounted() {},
  methods: {
    ...mapMutations('adminProjects', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('adminProjects', ['create', 'update']),
    async onSubmit() {
      this.$emit('submit')

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
